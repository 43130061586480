.google-visualization-table-tr-head {
	background-color: #1a1b1e !important;
}

.google-visualization-table-tr-even {
	background-color: #2c2e33 !important;
}

.google-visualization-table-tr-odd {
	background-color: #1a1b1e !important;
}

.google-visualization-table-th {
	background-image: inherit !important;
}

.google-visualization-table-table th, .google-visualization-table-td {
	border: none !important;
}
.answer table {
	border-collapse: collapse;
	border-radius: 10px;
}

.answer thead tr {
	color: #000;
	background-color: #C1C2C5;
	font-weight: 500;
}

.answer td {
	border: 1px solid #C1C2C5;
	padding: 8px;
}